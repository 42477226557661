import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from '../enviroments/environment';
import { PORTAL_IDENTITY } from '../enviroments/settings';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  isAuthenticated(token: any): Observable<boolean> {
    const url = `${environment.API_URL}/labeltest`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any>(url, { headers }).pipe(
      map((response) => {
        return response.data.status === '202';
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  saveToken(token: string): void {
    localStorage.setItem('token', token);
  }

  saveUserDetail(user: any): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUserDetail() {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  login(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labellogin`;
    return this.http.post<any>(url, requestBody);
  }

  viewportalusers(): Observable<any> {
    const url = `${environment.API_URL}/viewportalusers`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  adduser(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/adduser`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  verifyUploadFiles(filesArray: any): Observable<any> {
    const url = environment.VERIFY_UPLOAD_FILES_URL;
    const verifyData = filesArray.map(
      (file: { data_type: any; data_key: any }, index: number) => ({
        id: index + 1,
        data_type: file.data_type,
        data_key: file.data_key,
      })
    );
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.post<any>(url, verifyData);
  }

  modelmade(payload: any): Observable<any> {
    const url = `${environment.API_URL}/labelmodelmadeold`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(url, payload, { headers });
  }

  labelcreateagent(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelcreateagent`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelagentfbconnect(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelagentfbconnect`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelgetagentfbconnect(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelgetagentfbconnect`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelremoveagentfb(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelremoveagentfb`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelgetpages(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelgetpages`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelcreatecondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelcreatecondition`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelupdatecondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelupdatecondition`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labeldeletecondition(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labeldeletecondition`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labeladdconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/labeladdconnectedemails`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelupdateconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/labelupdateconnectedemails`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labeltoggleconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/labeltoggleconnectedemails`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labeldeleteconnectedemails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/email/labeldeleteconnectedemails`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labeluseragents(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labeluseragents`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(url, requestBody, { headers });
  }

  getagentdetails(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getagentdetails`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any>(url, { params: requestBody, headers });
  }

  labeladdprofilephoto(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labeladdprofilephoto `;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<any>(url, requestBody, { headers });
  }

  rephrase_condition(requestBody: any): Observable<any> {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/rephrase_condition`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  rephrase_what_to_say(requestBody: any): Observable<any> {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/rephrase_what_to_say`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  getconnectioninfo(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/getconnectioninfo`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelsaveplugin(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelsaveplugin`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelupdateplugin(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelupdateplugin`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labeldeleteplugin(plugin_id: any): Observable<any> {
    const url = `${environment.API_URL}/labeldeleteplugin`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('plugin_id', plugin_id.toString());
    return this.http.get<any>(url, { headers, params });
  }

  labeldeleteuser(user_id: any): Observable<any> {
    const url = `${environment.API_URL}/labeldeleteuser`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('user_id', user_id.toString());
    return this.http.get<any>(url, { headers, params });
  }

  setupstripe(): Observable<any> {
    const url = `${environment.API_URL}/setupstripe`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, { headers });
  }

  addcard(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/addcard`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  deletecard(): Observable<any> {
    const url = `${environment.API_URL}/deletecard`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  listcards(): Observable<any> {
    const url = `${environment.API_URL}/listcards`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  getportal(): Observable<any> {
    var portal_id = PORTAL_IDENTITY;
    const url = `${environment.API_URL}/getportal?portal_id=${portal_id}`;
    return this.http.get<any>(url);
  }

  getChatResponse(requestBody: any): Observable<any> {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL_1}/response`;
    return this.http.post<any>(url, requestBody);
  }

  portaluseragents(): Observable<any> {
    const url = `${environment.API_URL}/portaluseragents`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  getconnectioninfoportal(): Observable<any> {
    const url = `${environment.API_URL}/getconnectioninfoportal`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  labelgetconnectedinstagram(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelgetconnectedinstagram`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelsaveagentinstagraminfo(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelsaveagentinstagraminfo`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  toggleFacebookEnabled(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/toggleFacebookEnabled`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  toggleEmailEnabled(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/toggleFacebookEnabled`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  toggleWidgetEnabled(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/toggleWidgetEnabled`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  toggleStaticResponseEnabled(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/toggleStaticResponseEnabled`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  updateAccessSetings(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/updateAccessSetings`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  // wrokflow endpoint
  labelcreateworkflow(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelcreateworkflow`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  labelupdateworkflow(requestBody: any): Observable<any> {
    const url = `${environment.API_URL}/labelupdateworkflow`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.post<any>(url, requestBody, { headers });
  }

  allWorkflowInsights(): Observable<any> {
    const url = `${environment.API_URL}/allWorkflowInsights`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    return this.http.get<any>(url, { headers });
  }

  getworkflow(workflow_id: any): Observable<any> {
    const url = `${environment.API_URL}/getworkflow`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('workflow_id', workflow_id.toString());
    return this.http.post<any>(url, { headers, params });
  }

  labeldeleteworkflow(workflow_id: any): Observable<any> {
    const url = `${environment.API_URL}/labeldeleteworkflow`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set('workflow_id', workflow_id.toString());
    return this.http.post<any>(url, {}, { headers, params });
  }

  workflowtoggle(workflow_id: any): Observable<any> {
    const url = `${environment.API_URL}/workflowtoggle`;
    const token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('workflow_id', workflow_id.toString());
    return this.http.post<any>(url, { headers, params });
  }

  //chat
  workflows(requestBody: any) {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/workflows`;
    return this.http.post<any>(url, requestBody);
  }

  labelgetworkflowresponses(user_id: any): Observable<any> {
    const url = `${environment.API_URL}/labelgetworkflowresponses`;
    const token = this.getToken();
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    const params = new HttpParams().set('user_id', user_id);
    return this.http.get<any>(url, { headers, params });
  }

  //for direct chat with workflow
  workflows_direct(requestBody: any): Observable<any> {
    const url = `${environment.LANGCHAIN_CHATBOAT_URL}/workflows_direct`;
    return this.http.post<any>(url, requestBody);
  }
}
